import React from "react";
import Carousel from "nuka-carousel";

function MyCarousel(props) {
  return (
    <Carousel wrapAround={false}
      renderCenterLeftControls={LeftBtn}
      renderCenterRightControls={RightBtn}
      {...props}
    />
  );
}

export const LeftBtn = ({ previousSlide }) => {
  return (
    <button onClick={previousSlide} className="btn btn-carousel ml-2">
      <svg height={26} width={26} viewBox="0 0 100 100">
        <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path>
      </svg>
    </button>
  );
};

export const RightBtn = ({ nextSlide }) => {
  return (
    <button onClick={nextSlide} className="btn btn-carousel mr-2">
      <svg height={26} width={26} viewBox="0 0 100 100">
        <path
          d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
          transform="translate(100, 100) rotate(180) "
        ></path>
      </svg>
    </button>
  );
};

export default MyCarousel;
